import { useAppSelector } from '@/store/hooks/redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import { nativeClientStyles } from './NativeClientStyle';

const NativeAdPreview: FC<IExtendedPreviewPropsModel> = ({ caption, viewDate, companyExternalId }): JSX.Element => {
  const nativeClientStyle = companyExternalId ? nativeClientStyles[companyExternalId] || {} : {};
  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );
  const bidtheatreShareLink = useAppSelector((state) => state.propertyInfoSliceReducer.bidtheatreShareLink);
  return (
    <>
      <Grid container sx={{ border: '1px solid #231464', maxWidth: '700px', height: 'auto', overflow: 'hidden' }}>
        <Grid item xs={12} sx={{ minHeight: '325px', pb: 4, backgroundColor: '#FFF' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            <>
              <Typography
                sx={{
                  color: nativeClientStyle.primaryColor || '#000',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '300px',
                  height: '24px',
                }}
                variant="h5"
                fontFamily="GothamNarrowBook">
                <span>{viewDate && <div>{viewDate}</div>}</span>
              </Typography>
              <Typography
                sx={{ color: nativeClientStyle.primaryColor || '#000' }}
                variant="h5"
                fontFamily="GothamNarrowBook">
                <span>ANNONSE</span>
              </Typography>
            </>
          </Box>
          <Box
            component="a"
            target="_blank"
            href={bidtheatreShareLink}
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              textDecoration: 'none',
            }}>
            <img
              className=""
              src={media?.preSignedUrl}
              alt="property"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box
            sx={{
              float: 'left',
              position: 'relative',
              top: `${
                companyExternalId === ExternalCompanyIdEnum.EIE || companyExternalId === ExternalCompanyIdEnum.EIE_VISMA
                  ? '-64px'
                  : companyExternalId === ExternalCompanyIdEnum.STRAND_MAKLERI
                    ? '-58px'
                    : companyExternalId === ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI
                      ? '-100px'
                      : companyExternalId === ExternalCompanyIdEnum.WALLIN_CO_FASTIGHETSMAKLARE_AB
                        ? '-65px'
                        : companyExternalId === ExternalCompanyIdEnum.EIENDOM_BROERBY
                          ? '-120px'
                          : '-40px'
              }`,
              left: '17px',
            }}>
            <img
              style={{
                width: `${
                  companyExternalId === ExternalCompanyIdEnum.EIE ||
                  companyExternalId === ExternalCompanyIdEnum.EIE_VISMA
                    ? '55px'
                    : '100px'
                }`,
              }}
              src={nativeClientStyle.logo || '/images/eie/native_eie_logo_1.png'}
              alt="logo"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ zIndex: 10 }} p={2}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              color: nativeClientStyle.primaryColor || '#000',
            }}
            variant="h4"
            fontFamily={nativeClientStyle.fontFamily || nativeClientStyle.fontFamilyTwo || ''}>
            <span>{caption}</span>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default NativeAdPreview;
