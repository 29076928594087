import { BudgetVariantEnum, IBudgetModel } from '@/shared/models/budgets/budget.model';
import { AvailablePlatformsEnum, PlatformType } from '../constants/available-platforms';
import { IProductBudgetModel } from '@/shared/models/products/product.model';
import { platformsBySelectedType } from '../components/forms/ad-settings/types/platformTypes';
import { CreativeType } from '../constants/creatives-types.enum';
import { ISliderValuesPerPlatform } from '@/editor/store/reducers/selected-platforms.slice';

/**
 * Helper function to get the default percentage for a platform from a budget model
 */
const getDefaultPercentageForPlatform = (
  platform: PlatformType,
  selectedBudget: IBudgetModel | IProductBudgetModel
): number => {
  const budgetDefault = (selectedBudget as IBudgetModel).budgetItems?.find(
    (el) => el.type === platform
  )?.spreadBudgetPercentageDefault;

  const productDefault = (selectedBudget as IProductBudgetModel).productItems?.find(
    (el) => el.type === platform
  )?.spreadProductPercentageDefault;

  return budgetDefault || productDefault || 0;
};

/**
 * Find the appropriate slider value for a platform based on state and defaults
 */
export const findSliderValue = (
  platform: PlatformType,
  slidersState: ISliderValuesPerPlatform[],
  selectedBudget: IBudgetModel | IProductBudgetModel,
  activePlatformsFromLS: PlatformType[]
): number => {
  // If there are no slider states yet, get the default percentage from the budget model
  if (slidersState.length === 0) {
    return getDefaultPercentageForPlatform(platform, selectedBudget);
  }

  // If platform is active, use the slider value from state
  if (activePlatformsFromLS.includes(platform)) {
    return slidersState.find((el) => el.platform === platform)?.value || 0;
  }

  // Otherwise get the default percentage from the budget model
  return getDefaultPercentageForPlatform(platform, selectedBudget);
};

/**
 * Get available platforms based on budget type and creative type
 */
export const getPlatforms = (
  selectedBudget: IBudgetModel | IProductBudgetModel,
  selectedCreativeType: CreativeType
): PlatformType[] | undefined => {
  if (selectedBudget === null || selectedBudget === undefined) return;

  const isStandardBudget = selectedBudget.budgetVariant === BudgetVariantEnum.STANDARD;
  const isProductBudget = selectedBudget.budgetVariant === BudgetVariantEnum.PRODUCT;

  // Get the appropriate items array based on budget variant
  const items = isStandardBudget
    ? (selectedBudget as IBudgetModel).budgetItems
    : isProductBudget
      ? (selectedBudget as IProductBudgetModel).productItems
      : [];

  if (!items) return;

  return items
    .filter((el) => platformsBySelectedType[selectedCreativeType].includes(el.type as AvailablePlatformsEnum))
    .map((el) => el.type as PlatformType);
};
