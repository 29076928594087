export const EDITOR_LS_CONSTANTS = Object.freeze({
  PROPERTY_EXTERNAL_ID: 'propertyId',
  COMPANY_ID: 'companyId',
  PROPERTY_INTERNAL_ID: 'propertyInternalId',
  PROPERTY_CAROUSEL_INTERNAL_ID: 'propertyInternalCarouselId',
  DOWNLOADED_CAROUSEL_DATA: 'downloadedCarouselData',
  DOWNLOADED_CAROUSEL_INDEX: 'downloadedCarouselIndex',
  PROPERTY_ARRAY: 'propertyArray',
  PROPERTY_CAROUSELL_ID: 'propertyCarouselId',
  TEMPLATE_TYPE: 'templateType',
  PROPERTY_ESTATE_TYPE: 'propertyEstateType',
  CREATE_ORDER_ACTIVE_STEP: 'createOrderActiveStep',
  ACTIVE_SOCIAL_MEDIA_PLATFORMS: 'activeSocialMediaPlatforms',
  CREATED_CAMPAIGNS: 'createdCampaigns',
  CREATED_AD_SETS: 'createdAdSets',
  CREATED_CREATIVES: 'createdCreatives',
  ORDER_TITLE: 'orderTitle',
  ORDER_INTERNAL_ID: 'orderInternalId',
  SELECTED_CREATIVE_TYPE: 'selectedCreativeType',
  CAMPAIGN_FORM: 'campaignForm',
  ADSET_FORM: 'adsetForm',
  CREATIVES_FORMS: {
    meta: 'creativeForms_meta',
    snapchat: 'creativeForms_snapchat',
    bidtheatre: 'creativeForms_bidtheatre',
    boards: 'creativeForms_boards',
    delta: 'creativeForms_delta',
  },
});
