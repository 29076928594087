import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { getValueFromLs } from '@/shared/services/localstorage';
import { RootState } from '@/store/store';
import { createSelector } from '@reduxjs/toolkit';
import { ICreativeFormsStatePerPlatform } from '../reducers/creative-step.slice';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';

// Basic selectors
const selectCreativeStepState = (state: RootState) => state.creativeStepSliceReducer;
const selectEditorPlatformsState = (state: RootState) => state.editorActivePlatformsReducer;

// Derived selectors
export const selectSelectedCreativeType = createSelector(
  [selectCreativeStepState],
  (creativeState) => creativeState.selectedCreativeType
);

export const selectCreativeFormsState = createSelector(
  [selectCreativeStepState],
  (creativeState) => creativeState.creativeFormsState
);

export const selectIsCreativeFormInitialized = createSelector(
  [selectCreativeStepState],
  (creativeState) => creativeState.isInitialized
);

export const selectCreativeSubmissionProgress = createSelector(
  [selectCreativeStepState],
  (creativeState) => creativeState.creativeSubmissionProgress
);

export const selectActivePlatformsIds = createSelector(
  [selectEditorPlatformsState],
  (platformsState) => platformsState.activePlatforms
);

// Complex selectors
export const selectActivePlatforms = createSelector([selectActivePlatformsIds], (platformIds) => {
  const activePlatformsFromLS = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  return platformIds.length === 0 ? activePlatformsFromLS : platformIds;
});

export const selectActivePlatformsExcludingSpecific = createSelector(
  [selectActivePlatforms, (_, excludedPlatforms: PlatformType[] = []) => excludedPlatforms],
  (platforms, excludedPlatforms) => platforms.filter((platform: PlatformType) => !excludedPlatforms.includes(platform))
);

export const selectCreativeFormForPlatform = createSelector(
  [
    selectCreativeFormsState,
    (_, platform: PlatformType) => platform,
    (_, __, creativeType?: string) => creativeType || null,
  ],
  (formsState, platform, creativeType) => {
    if (!creativeType) {
      return formsState[platform];
    }
    return formsState[platform][creativeType as keyof ICreativeFormsStatePerPlatform];
  }
);

// Special platform selectors
export const selectBidtheatreCreativeForm = createSelector(
  [selectCreativeFormsState],
  (formsState) => formsState[AvailablePlatformsEnum.BIDTHEATRE][CreativeTypesEnum.SINGLE]
);

export const selectBoardsCreativeForm = createSelector(
  [selectCreativeFormsState],
  (formsState) => formsState[AvailablePlatformsEnum.BOARDS][CreativeTypesEnum.SINGLE]
);

// Validation selectors
export const selectAllRequiredCreativeForms = createSelector(
  [selectCreativeFormsState, selectSelectedCreativeType, selectActivePlatforms],
  (formsState, selectedType, platforms) => {
    return platforms.map((platform: PlatformType) => ({
      platform,
      creativeForm: formsState[platform][selectedType as keyof ICreativeFormsStatePerPlatform],
    }));
  }
);

// Filter platforms by creative type compatibility
export const selectPlatformsForCreativeType = createSelector(
  [selectActivePlatforms, (_, creativeType: string) => creativeType],
  (platforms, creativeType) => {
    // Handle special cases for certain creative types
    if (creativeType === CreativeTypesEnum.VIDEO) {
      return {
        videoCompatible: platforms.filter(
          (p: PlatformType) => p !== AvailablePlatformsEnum.BIDTHEATRE && p !== AvailablePlatformsEnum.BOARDS
        ),
        singleCompatible: platforms.filter(
          (p: PlatformType) => p === AvailablePlatformsEnum.BIDTHEATRE || p === AvailablePlatformsEnum.BOARDS
        ),
      };
    }

    return platforms;
  }
);
