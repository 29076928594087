import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, SyntheticEvent, useState, type JSX } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import { CardHeaderPlatformIcon } from '../../creatives-step/components/CardHeaderPlatformIcon';
import BasicSummary from './BasicSummary';
import DeltaSummary from './DeltaSummary';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import Loader from '@/shared/components/Loader';
import MetaPreviewSummary from '@/shared/components/platform-previews/previews/meta/MetaPreviewSummary';
import SnapChatPreviewSummary from '@/shared/components/platform-previews/previews/snapchat/SnapChatPreviewSummary';
import BidtheatrePreviewSummary from '@/shared/components/platform-previews/previews/bidtheatre/BidTheatrePreviewSummary';
import BidtheatreSummary from './BidTheatreSummary';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import ArrowBackTwoTone from '@mui/icons-material/ArrowBackTwoTone';
import Send from '@mui/icons-material/Send';
import useRestoreState from '../../creatives-step/hooks/useRestoreState';
import BoardsPreviewSummary from '@/shared/components/platform-previews/previews/boards/BoardsPreviewSummary';
import BoardsSummary from './BoardsSummary';

export interface ISummaryProps {
  platformList: PlatformType[];
  onSubmit(): void;
  handleBackButtonClicked?(): void;
  createAdsLoading: boolean;
}

const Summary: FC<ISummaryProps> = ({
  platformList,
  onSubmit,
  handleBackButtonClicked,
  createAdsLoading,
}): JSX.Element => {
  const { t } = useTranslation();
  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const isCreativeFormInitialized = useAppSelector((state) => state.creativeStepSliceReducer.isInitialized);
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const [expanded, setExpanded] = useState<number | false>(0);

  platformList.forEach((el) => {
    if (!isCreativeFormInitialized) {
      useRestoreState(el, isCreativeFormInitialized);
    }
  });

  const handleChange = (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { data: userData, isLoading: userDataLoading } = useGetMeQuery();

  return (
    <div>
      {userDataLoading ? (
        <Loader />
      ) : (
        <Grid container gap={1} p={1}>
          <Grid item xs={12}>
            {platformList.map((el, key) => {
              const currentCreativeForm = creativeFormsState[el];

              return (
                <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <CardHeaderPlatformIcon platformId={el} />
                      {t(el.charAt(0) + el.slice(1)).toUpperCase()}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={6}>
                        <Grid container flexDirection="column" gap={2}>
                          {el !== AvailablePlatformsEnum.BIDTHEATRE && el !== AvailablePlatformsEnum.BOARDS && (
                            <BasicSummary
                              values={currentCreativeForm[selectedCreativeType]!}
                              platformsFromStoreIds={platformList}
                            />
                          )}
                          {el === AvailablePlatformsEnum.DELTA && (
                            <DeltaSummary currentCreativeForm={currentCreativeForm['single']!} />
                          )}
                          {el === AvailablePlatformsEnum.BIDTHEATRE && (
                            <BidtheatreSummary currentCreativeForm={currentCreativeForm['single']!} />
                          )}
                          {el === AvailablePlatformsEnum.BOARDS && (
                            <BoardsSummary currentCreativeForm={currentCreativeForm['single']!} />
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {el === AvailablePlatformsEnum.META && (
                          <MetaPreviewSummary selectedType={selectedCreativeType} />
                        )}
                        {el === AvailablePlatformsEnum.SNAPCHAT && (
                          <SnapChatPreviewSummary selectedType={selectedCreativeType} />
                        )}
                        {el === AvailablePlatformsEnum.BIDTHEATRE && (
                          <BidtheatrePreviewSummary
                            values={currentCreativeForm['single']!}
                            externalCompanyId={userData?.companyExternalId || ''}
                            profileTitle={userData?.profile?.title || ''}
                          />
                        )}
                        {el === AvailablePlatformsEnum.BOARDS && (
                          <BoardsPreviewSummary
                            values={currentCreativeForm['single']!}
                            externalCompanyId={userData?.companyExternalId || ''}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <Accordion expanded={true}>
              <AccordionSummary>
                <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                  <CommonButton
                    disabled={createAdsLoading}
                    size="small"
                    onClick={handleBackButtonClicked}
                    variant={'outlined'}
                    startIcon={<ArrowBackTwoTone fontSize="small" />}>
                    {t('back')}
                  </CommonButton>
                  <CommonButton
                    sx={{
                      backgroundColor: '#ffa737',
                      '&:hover': {
                        backgroundColor: '#e6952f',
                        color: '#FFF',
                      },
                      color: '#000',
                    }}
                    disabled={createAdsLoading}
                    size="medium"
                    onClick={onSubmit}
                    endIcon={<Send fontSize="small" />}>
                    {t('submit')}
                  </CommonButton>
                </Box>
              </AccordionSummary>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Summary;
