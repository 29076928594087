export enum ExternalCompanyIdEnum {
  EIE = '264',
  EIE_VISMA = '47', // TODO = check with production
  SORMEGLEREN = 'MSSORM',
  PROACTIV = '284',
  BRORBY = '?',
  SMG = 'MDAtMXwwMDAwMDAwMjMwM3wyODQ.',
  MSPECS = 'MDAtMXwwMDAwMDAwMjQ0N3wyODQ.',
  FREMEIENDOMSMEGLING = '340',
  STRAND_MAKLERI = 'MDAtMXwwMDAwMDAwMjcyNnwyODQ.',
  STUDIO_FASTIGHETSMAKLERI = 'MDAtMXwwMDAwMDAwMjcyN3wyODQ.',
  HOME_BY_DEAN = 'MDAtMXwwMDAwMDAwMjcyOXwyODQ.',
  MAKLARTEAMET = 'MDAtMXwwMDAwMDAwMjc2MHwyODQ.',
  WALLIN_CO_FASTIGHETSMAKLARE_AB = 'MDAtMXwwMDAwMDAwMjcyNXwyODQ.',
  H_MAKLARE_AB = 'MDAtMXwwMDAwMDAwMjc2OHwyODQ.',
  CHRISTANDER = 'MDAtMXwwMDAwMDAwMjc1OXwyODQ.',
  EIENDOM_BROERBY = '284',
}
