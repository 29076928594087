import Box from '@mui/material/Box';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';

import type { JSX } from 'react';

const FacebookSinglePreview = (): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState.meta.single.media);
  const shareLink = useAppSelector((state) => state.propertyInfoSliceReducer.shareLink);

  return (
    <Box component="div">
      {media?.preSignedUrl ? (
        <Box
          sx={{
            display: 'flex',
            maxWidth: '600px',
            maxHeight: '400px',
            justifyContent: 'center',
            overflow: 'hidden',
          }}>
          <Box
            component="a"
            href={shareLink}
            target='_blank'
            sx={{
              display: 'block',
              width: '100%',
              height: '100%',
              transition: 'transform 0.2s ease',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.02)', // Subtle zoom on hover
              },
            }}>
            <img
              src={media?.preSignedUrl ?? ''}
              style={{
                objectFit: 'contain',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                display: 'block',
              }}
              alt="dynamic-image"></img>
          </Box>
        </Box>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default FacebookSinglePreview;
