import { useCreateAndUploadOverlayFromPropertyIdMutation } from '@/editor/services/http/media-library.api';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import { useCallback } from 'react';
import { creativeFormService } from '../services';
import { PlatformType } from '@/editor/shared/constants/available-platforms';

const useOverlayManagement = () => {
  const activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);

  const [createOverlayFromPropertyId, { isLoading, isError, isSuccess }] =
    useCreateAndUploadOverlayFromPropertyIdMutation();

  const getOverlayData = useCallback(
    async (propertyInternalId: string, images: IMediaLibraryImageModel[]) => {
      try {
        const res = await createOverlayFromPropertyId({
          internalPropertyId: propertyInternalId,
          images: images,
        });

        if (activePlatforms.length > 0) {
          activePlatforms.forEach((platform: PlatformType) => {
            creativeFormService.setFormValues(res.data?.data!, platform, selectedCreativeType);
          });
        }

        return { ...res, activePlatforms };
      } catch (error) {
        console.error('Error creating overlay:', error);
        throw error;
      }
    },
    [createOverlayFromPropertyId, activePlatforms, selectedCreativeType]
  );

  return { getOverlayData, loadingOverlay: isLoading };
};

export default useOverlayManagement;

// 1. merge aftermarketing data with creative form values
// 2. dispatch the values
// 3. get image and dispatch image to creative
// 4. use functions from MediaImageLibraryItem
// 5. test/verify/refactor/push/merge
