import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
import WebmeglerBoardsSinglePreview from './WebmeglerBoardsSinglePreview';

const BoardsPreviewResolver: FC<IExtendedPreviewPropsModel> = ({
  caption,
  error,
  errorMessage,
  companyExternalId,
  employeeName,
  employeeAvatar,
  employeePhone,
  employeeEmail,
  link,
  doohBrokerText,
  doohTemplateType,
  areaInUsage,
  viewDate,
  propertyType,
  propertyDebt,
  propertyPriceQuote,
  nativeAd,
  description,
  ownershipType,
}): JSX.Element => {
  const switchPreview = (companyExternalId: string) => {
    switch (companyExternalId) {
      case ExternalCompanyIdEnum.EIE:
      case ExternalCompanyIdEnum.EIE_VISMA:
        return (
          <WebmeglerBoardsSinglePreview
            caption={caption}
            employeeName={employeeName}
            employeeAvatar={employeeAvatar}
            employeePhone={employeePhone}
            employeeEmail={employeeEmail}
            link={link}
            doohBrokerText={doohBrokerText}
            doohTemplateType={doohTemplateType}
            areaInUsage={areaInUsage}
            viewDate={viewDate}
            propertyDebt={propertyDebt}
            propertyType={propertyType}
            propertyPriceQuote={propertyPriceQuote}
            description={description}
            ownershipType={ownershipType}
          />
        );
      default:
        return (
          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6">No preview available</Typography>
          </Box>
        );
    }
  };

  return (
    <div>
      <Box component="div">
        <Paper sx={{ border: error ? '1px solid red' : '', p: 1, justifyContent: 'center', display: 'flex' }}>
          {error && errorMessage && (
            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="h6" color="error" sx={{ p: 2, pl: 3 }}>
                {errorMessage}
              </Typography>
            </Box>
          )}
          <div>{switchPreview(companyExternalId as string)}</div>
        </Paper>
      </Box>
    </div>
  );
};

export default BoardsPreviewResolver;
