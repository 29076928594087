import { IBaseModel } from '../base.model';
import { IBudgetItemModel } from './budget-item.model';

export interface IBudgetModel extends IBaseModel {
  title: string;
  companyId?: string;
  companyTitle?: string;
  type: BudgetType;
  value: number;
  tag?: string;
  valueToInvoice: number;
  indexToShow: number;
  estateTypes: string[] | null;
  budgetItems: IBudgetItemModel[];
  overlay: boolean;
  overlayDefault: boolean;
  includedOffices: string[] | null;
  excludedOffices: string[] | null;
  budgetVariant: BudgetVariantEnum.STANDARD;
}

export type BudgetType = 'regular' | 'automatic' | 'aftersold';

export type BudgetVariant = BudgetVariantEnum.STANDARD | BudgetVariantEnum.PRODUCT;

export enum BudgetVariantEnum {
  STANDARD = 'standard',
  PRODUCT = 'product',
}
