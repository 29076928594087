import moment from 'moment';
import { FC, useEffect, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { useUpdateAdSetMutation } from '@/editor/services/http/ad-sets.api';
import {
  useCreateSingleCreativesBrokerBatchMutation,
  useUpdateCarouselCreativeMutation,
  useUpdateDynamicCreativeMutation,
  useUpdateSingleCreativeMutation,
  useUpdateVideoCreativeMutation,
} from '@/editor/services/http/creatives.api';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '../../../../shared/constants/creatives-types.enum';
import AdSettingsForm, { IAdSettingsFormValues } from '../../../../shared/components/forms/ad-settings/AdSettingsForm';
import CreativesTypeResolver from '../../../../shared/components/forms/creatives/CreativesTypesResolver';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { setcreativeSubmissionProgress } from '@/editor/store/reducers/creative-step.slice';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IAdSetLocationModel } from '@/shared/models/adsets/adset-location.model';
import { ICircleStoreModel } from '@/editor/store/reducers/adSet-step.slice';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { isCompanyQualifiedForBrokerService } from '@/editor/shared/services/companyService';

export interface IFullAdvertisementWrapperProps {
  advertisements: IFullAdvertisementModel[];
  budgets: IBudgetModel[];
  activeBudget: string;
  activePlatforms: PlatformType[];
  bidtheatreCreativeId: any;
  boardsCreativeId: any;
}

// TODO REFACTOR !!!

const FullAdvertisementWrapper: FC<IFullAdvertisementWrapperProps> = ({
  advertisements,
  budgets,
  activeBudget,
  activePlatforms,
  bidtheatreCreativeId,
  boardsCreativeId,
}): JSX.Element => {
  const [updateSingleCreative] = useUpdateSingleCreativeMutation();
  const [updateDynamicCreative] = useUpdateDynamicCreativeMutation();
  const [updateCarouselCreative] = useUpdateCarouselCreativeMutation();
  const [updateVideoCreative] = useUpdateVideoCreativeMutation();
  const [updateAdSet, { isLoading: adSetLoading }] = useUpdateAdSetMutation();
  const [brokerMutation] = useCreateSingleCreativesBrokerBatchMutation();
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const dispatch = useAppDispatch();
  const { selectedCreativeType, creativeFormsState } = useAppSelector((state) => state.creativeStepSliceReducer);
  const { t } = useTranslation();
  const { data: userData, isLoading: userDataLoading } = useGetMeQuery();
  const findAd = () => {
    const metaAd = advertisements.find((el) => el.platform === AvailablePlatformsEnum.META);
    return metaAd ? metaAd : advertisements[0];
  };

  const advertisement = findAd();

  useEffect(() => {
    createCirclesFromLocationData(advertisement.adSet.locations);
  }, [advertisements]);

  useEffect(() => {
    if (!formSubmissionProgress.update || !formSubmissionProgress.active) return;
    if (formSubmissionProgress.platform !== null) {
      let creativeType = selectedCreativeType;

      if (
        formSubmissionProgress.platform === AvailablePlatformsEnum.DELTA ||
        formSubmissionProgress.platform === AvailablePlatformsEnum.BIDTHEATRE ||
        formSubmissionProgress.platform === AvailablePlatformsEnum.BOARDS
      ) {
        creativeType = CreativeTypesEnum.SINGLE;
      }

      handleCreativeUpdated(
        formSubmissionProgress.platform as PlatformType,
        creativeFormsState[formSubmissionProgress.platform as PlatformType][creativeType]
      ).then(() => {
        dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: null }));
      });
    }
  }, [formSubmissionProgress.active]);

  const handleCreativeUpdated = async (platform: PlatformType, values: any) => {
    const ad = advertisements.find((el) => el.platform === platform);
    let method = null;

    if (!ad) return;

    switch (ad?.creative.creativeType) {
      case CreativeTypesEnum.SINGLE:
        method = updateSingleCreative;
        break;
      case CreativeTypesEnum.DYNAMIC:
        method = updateDynamicCreative;
        break;
      case CreativeTypesEnum.CAROUSEL:
        method = updateCarouselCreative;
        break;
      case CreativeTypesEnum.VIDEO:
        method = updateVideoCreative;
        break;
      default:
        break;
    }

    const id = ad.creative.id;

    if (method) {
      try {
        if (
          platform === AvailablePlatformsEnum.BIDTHEATRE &&
          isCompanyQualifiedForBrokerService(userData?.companyExternalId!)
        ) {
          await toast.promise(
            (async () => {
              await method({ id, platform, creative: values }).unwrap();
              await brokerMutation({
                id: bidtheatreCreativeId,
                creatives: [{ id, platform, creative: values }],
              }).unwrap();
            })(),
            {
              loading: t('processing'),
              success: <b>{t('successfulUpdate')}</b>,
              error: (err: any) => {
                if (err?.data?.length > 10) {
                  return 'Unable to update creative. Changes will be reverted!';
                }
                return err?.data;
              },
            }
          );
        } else if (platform === AvailablePlatformsEnum.BOARDS) {
          await toast.promise(
            (async () => {
              await method({ id, platform, creative: values }).unwrap();
              await brokerMutation({
                id: boardsCreativeId,
                creatives: [{ id, platform, creative: values }],
              }).unwrap();
            })(),
            {
              loading: t('processing'),
              success: <b>{t('successfulUpdate')}</b>,
              error: (err: any) => {
                if (err?.data?.length > 10) {
                  return 'Unable to update creative. Changes will be reverted!';
                }
                return err?.data;
              },
            }
          );
        } else {
          await toast.promise(method({ id, platform, creative: values }).unwrap(), {
            loading: t('processing'),
            success: <b>{t('successfulUpdate')}</b>,
            error: (err: any) => {
              if (err?.data?.length > 10) {
                return 'Unable to update creative. Changes will be reverted!';
              }
              return err?.data;
            },
          });
        }
      } catch (err) {
        console.error('Error updating creative:', err);
      }
    }
  };

  const handleAdSetUpdate = async (value: IAdSettingsFormValues) => {
    const { title, places, endTime } = value;
    const { id } = advertisement.adSet;
    try {
      await toast.promise(updateAdSet({ id, value: { title, places, endTime } }).unwrap(), {
        loading: 'Processing...',
        success: <b>Successfully updated</b>,
        error: (err: any) => {
          if (err?.data?.length > 10) {
            return 'Unable to update ad set. Changes will be reverted!';
          }
          return <b>Something happened</b>;
        },
      });
    } catch (err) {
      console.error('Error updating ad set:', err);
    }
  };

  const createCirclesFromLocationData = (locations: IAdSetLocationModel[]): ICircleStoreModel[] => {
    const places = locations.map((el) => {
      return {
        address: el.address as string,
        radius: el.radius * 1000, // convert to meters
        center: { lat: el.latitude, lng: el.longitude },
      };
    });

    // dispatch(setPlaceArray(places));
    return places;
  };

  return (
    <Box mt={1}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>{t('updateSettings')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <AdSettingsForm
              initialValues={{
                title: advertisement.campaign.title || '',
                places: createCirclesFromLocationData(advertisement.adSet.locations),
                startTime: moment(advertisement.adSet.startTime).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(advertisement.adSet.endTime).format('YYYY-MM-DD HH:mm:ss'),
                budgetId: activeBudget,
              }}
              budgets={budgets!}
              onSubmitForm={handleAdSetUpdate}
              actionLoading={adSetLoading}
              updating={true}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>{t('updateCreative')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <CreativesTypeResolver
              availablePlatforms={activePlatforms}
              creativeType={advertisement.creative.creativeType!}
              updating={true}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FullAdvertisementWrapper;
