import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { ICreativeStepState } from '@/editor/store/reducers/creative-step.slice';
import { IMediaLibraryDrawerState } from '@/editor/store/reducers/modals-drawers.slice';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';

const useCheckIfSelectedImage = (
  drawerState: IMediaLibraryDrawerState,
  creativeState: ICreativeStepState,
  image?: IMediaLibraryImageModel
) => {
  const checkSingle = (
    image: IMediaLibraryImageModel,
    platform: PlatformType,
    creativeStepState: ICreativeStepState
  ): boolean => {
    if (drawerState.creativeType !== CreativeTypesEnum.SINGLE) return false;
    return creativeStepState.creativeFormsState[platform][CreativeTypesEnum.SINGLE]?.media?.id === image.id;
  };

  const checkCarousel = (
    image: IMediaLibraryImageModel,
    platform: PlatformType,
    creativeStepState: ICreativeStepState
  ): boolean => {
    if (drawerState.creativeType !== CreativeTypesEnum.CAROUSEL) return false;

    return (
      creativeStepState.creativeFormsState[platform][CreativeTypesEnum.CAROUSEL]!.creativeItems.find(
        (el) => el.creativeMedia?.id === image.id
      ) !== undefined
    );
  };

  const checkDynamic = (
    image: IMediaLibraryImageModel,
    platform: PlatformType,
    creativeStepState: ICreativeStepState
  ): boolean => {
    if (drawerState.creativeType !== CreativeTypesEnum.DYNAMIC) return false;

    return (
      creativeStepState.creativeFormsState[platform][CreativeTypesEnum.DYNAMIC]?.creativeMedias.find(
        (el) => el.id === image.id
      ) !== undefined
    );
  };

  const isImageSelectedByPlatform = (platform: PlatformType): boolean => {
    if (!image) {
      return false;
    }

    const creativeType =
      platform === AvailablePlatformsEnum.DELTA ||
      platform === AvailablePlatformsEnum.BIDTHEATRE ||
      platform === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType!;

    switch (creativeType) {
      case CreativeTypesEnum.DYNAMIC:
        return checkDynamic(image, platform, creativeState);
      case CreativeTypesEnum.SINGLE:
        return checkSingle(image, platform, creativeState);
      case CreativeTypesEnum.CAROUSEL:
        return checkCarousel(image, platform, creativeState);
      default:
        return false;
    }
  };

  const isImageSomeWhereSelected = () => {
    if (!image) {
      return false;
    }

    const platformKeys = Object.values(AvailablePlatformsEnum);
    let found = false;
    for (let i = 0; i < platformKeys.length; i++) {
      if (found) break;

      if (
        platformKeys[i] === AvailablePlatformsEnum.DELTA ||
        platformKeys[i] === AvailablePlatformsEnum.BIDTHEATRE ||
        platformKeys[i] === AvailablePlatformsEnum.BOARDS
      ) {
        found = checkSingle(image, platformKeys[i], creativeState);
        continue;
      }

      if (
        checkSingle(image, platformKeys[i], creativeState) ||
        checkDynamic(image, platformKeys[i], creativeState) ||
        checkCarousel(image, platformKeys[i], creativeState)
      ) {
        found = true;
        break;
      }
    }

    return found;
  };

  return { isImageSelectedByPlatform, isImageSomeWhereSelected };
};

export default useCheckIfSelectedImage;
