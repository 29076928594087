import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { buildYupTimeDateProperty } from '@/shared/services/utils/moment-utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormikProvider, useFormik } from 'formik';
import { FC, JSX, useEffect } from 'react';
import * as yup from 'yup';
import CommonButton from '../../../../../shared/components/CommonButton';
import ConfiguredGoogleMap from './ConfiguredGoogleMap';
import AdSetPartialForm from './AdSetPartialForm';
import CampaignPartialForm from './CampaignPartialForm';
import SelectPlatforms from './SelectPlatforms';
import { useTranslation } from 'react-i18next';
import CreativeTypesEnum, { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  setOverlayAvailable,
  setOverlayDefault,
  setSelectedCreativeType,
} from '@/editor/store/reducers/creative-step.slice';
import ArrowForward from '@mui/icons-material/ArrowForward';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { ICircleStoreModel, setAdSetStepValues, setAdsetValuesUpdated } from '@/editor/store/reducers/adSet-step.slice';
import moment from 'moment';
import { IProductBudgetModel } from '@/shared/models/products/product.model';

// TODO REFACTOR

export interface IAdSettingsFormValues {
  title: string;
  places: ICircleStoreModel[];
  startTime: string;
  endTime: string;
  budgetId: string | null;
  productId?: string | null;
  budgetValue?: number;
}

export interface IAdSettingsFormProps {
  budgets: IBudgetModel[];
  products?: IProductBudgetModel[];
  actionLoading: boolean;
  updating?: boolean;
  initialPlaces?: ICircleStoreModel[] | null;
  onSubmitForm: (values: IAdSettingsFormValues) => void;
  initialValues: IAdSettingsFormValues;
  handleBackBtnClicked?(): void;
}

const buttons = [
  { type: CreativeTypesEnum.SINGLE, text: 'standard' },
  { type: CreativeTypesEnum.DYNAMIC, text: 'abTest' },
  { type: CreativeTypesEnum.CAROUSEL, text: 'carousel' },
  { type: CreativeTypesEnum.VIDEO, text: 'video' },
];

const AdSettingsForm: FC<IAdSettingsFormProps> = ({
  products,
  budgets,
  actionLoading,
  initialValues,
  onSubmitForm,
  updating = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const dispatch = useAppDispatch();
  const storedAdSetValues = useAppSelector((state) => state.adSetStepSliceReducer);

  useEffect(() => {
    if (!storedAdSetValues.valuesFetchedFromLocalStorage) {
      dispatch(
        setAdSetStepValues({
          adSet: getAdsetPartial(initialValues),
          circles: initialValues.places,
          valuesUpdated: false,
          valuesFetchedFromLocalStorage: true,
        })
      );
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (storedAdSetValues.valuesUpdated && !updating) {
      initialValues = { ...storedAdSetValues.adSet, places: storedAdSetValues.places };
      formik.setValues(initialValues);
      dispatch(setAdsetValuesUpdated(false));
    }
  }, [initialValues, storedAdSetValues.valuesUpdated]);

  useEffect(() => {
    if (storedAdSetValues.places.length > 0) {
      formik.setFieldValue('places', storedAdSetValues.places);
    }
  }, [storedAdSetValues.places]);

  const formik = useFormik({
    initialValues: initialValues,

    validateOnChange: false,
    validationSchema: yup.object().shape({
      title: yup.string().required(t('Title is required')),
      places: yup.array().required(t('Places is required')).min(1),
      startTime: buildYupTimeDateProperty(t('Start time'), true, false, 0, 0),
      endTime: buildYupTimeDateProperty(t('End time'), false, true, 7, 0),
      budgetId: yup.string().required(t('Budget is required')),
      budgetValue: yup.number().nullable(),
      // .when(['productId', 'budgetId'], {
      //   test: (productId: any, budgetId: any) => productId === budgetId,
      //   then: yup.number().required(t('Spend value is required')).min(2, t('Spend value is required')),
      //   otherwise: yup.number().nullable(),
      // }),
    }),

    onSubmit: async (values: IAdSettingsFormValues) => {
      if (formik.isValid) {
        onSubmitForm(values);
        dispatch(
          setAdSetStepValues({
            adSet: getAdsetPartial(values),
            circles: formik.values.places,
            valuesUpdated: true,
            valuesFetchedFromLocalStorage: true,
          })
        );
      }
    },
  });

  useEffect(() => {
    let selectedBudget = budgets?.find((el) => el.id === formik.getFieldMeta('budgetId').value);
    if (selectedBudget) {
      dispatch(setOverlayAvailable(selectedBudget?.overlay as boolean));
      dispatch(setOverlayDefault(selectedBudget?.overlayDefault as boolean));
    }

    let selectedProduct = products?.find((el) => el.id === formik.getFieldMeta('productId').value);
    if (selectedProduct) {
      dispatch(setOverlayAvailable(selectedProduct?.overlay as boolean));
    }
  }, [formik.getFieldMeta('budgetId').value]);

  useEffect(() => {
    if (formik.isValid && !updating) {
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ADSET_FORM, JSON.stringify(formik.values));
    }
  }, [formik.values]);

  const handleSetTypeOfCreative = (creativeType: CreativeType) => {
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.SELECTED_CREATIVE_TYPE, creativeType);
    dispatch(setSelectedCreativeType({ creativeType }));
  };

  const getAdsetPartial = (
    values: IAdSettingsFormValues
  ): Pick<IAdSettingsFormValues, 'title' | 'startTime' | 'endTime' | 'budgetId' | 'productId' | 'budgetValue'> => {
    return {
      title: values.title,
      startTime: moment(values.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(values.endTime).format('YYYY-MM-DD HH:mm:ss'),
      budgetId: values.budgetId,
      productId: values.productId,
      budgetValue: values.budgetValue,
    };
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <form
          noValidate
          onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
          onSubmit={formik.handleSubmit}>
          <Grid container sx={{ p: 1 }} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid container spacing={{ xs: 3, sm: 3 }}>
                    {!updating ? (
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="h6" color="secondary">
                            {t('selectAdType')}
                          </Typography>
                          <ButtonGroup variant="contained" sx={{ mt: '10px' }} color="secondary" size="medium">
                            {buttons.map((el) => (
                              <Button
                                className={selectedCreativeType === el.type ? 'btn-selected' : ''}
                                onClick={() => handleSetTypeOfCreative(el.type)}
                                key={el.type}>
                                {t(el.text)}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    <Grid item xs={12}>
                      <CampaignPartialForm />
                      <AdSetPartialForm updating={updating} budgets={budgets} products={products!} />
                      {!updating && (
                        <SelectPlatforms
                          updating={updating}
                          selectedBudget={
                            budgets?.find((el) => el.id === formik.getFieldMeta('budgetId').value)! ||
                            products?.find((el) => el.id === formik.getFieldMeta('productId').value)!
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: '100%', minHeight: '610px' }}>
                <CardContent>
                  <Grid container sx={{ minHeight: '500px' }}>
                    <Grid item xs={12}>
                      <ConfiguredGoogleMap />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {!updating ? (
                <Card sx={{ backgroundColor: '#FFF', display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <CommonButton
                    size="small"
                    type="submit"
                    disabled={actionLoading}
                    endIcon={<ArrowForward fontSize="small" />}>
                    {t('submit')}
                  </CommonButton>
                </Card>
              ) : (
                <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <CommonButton
                    size="small"
                    type="submit"
                    disabled={actionLoading}
                    endIcon={<SendOutlined fontSize="small" />}>
                    {t('update')}
                  </CommonButton>
                </Card>
              )}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </div>
  );
};

export default AdSettingsForm;
