import {
  useCreateCarouselCreativesBatchMutation,
  useCreateDynamicCreativesBatchMutation,
  useCreateSingleCreativesBatchMutation,
  useCreateSingleCreativesBrokerBatchMutation,
  useCreateVideoCreativesBatchMutation,
} from '@/editor/services/http/creatives.api';
import { CreateCreativesService } from '../services/createCreativesService';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';

export const useCreativesService = () => {
  const { t } = useTranslation();

  const [createSingleCreativesBatch, { isLoading: singleCreativeCreationLoading }] =
    useCreateSingleCreativesBatchMutation();
  const [createDynamicCreativesBatch, { isLoading: dynamicCreativeCreationLoading }] =
    useCreateDynamicCreativesBatchMutation();
  const [createCarouselCreativesBatch, { isLoading: carouselCreativeCreationLoading }] =
    useCreateCarouselCreativesBatchMutation();
  const [createVideoCreativeBatch, { isLoading: videoCreativeCreationLoading }] =
    useCreateVideoCreativesBatchMutation();

  const [brokerMutation, { isLoading: brokerIsLoading }] = useCreateSingleCreativesBrokerBatchMutation();
  const { data: userData, isLoading: userDataLoading } = useGetMeQuery();

  const creativeService = new CreateCreativesService(t, userData?.companyExternalId as ExternalCompanyIdEnum, {
    createSingleCreativesBatch,
    createDynamicCreativesBatch,
    createCarouselCreativesBatch,
    createVideoCreativeBatch,
    brokerMutation,
  });

  return {
    creativeService,
    isLoading:
      userDataLoading ||
      singleCreativeCreationLoading ||
      dynamicCreativeCreationLoading ||
      carouselCreativeCreationLoading ||
      videoCreativeCreationLoading ||
      brokerIsLoading,
  };
};
