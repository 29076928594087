import { useGetOrderWithDetailsByIdQuery } from '@/editor/services/http/orders.api';
import { AvailablePlatformsEnum } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { TemplateType } from '@/editor/shared/constants/template-type.enum';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import { useParams } from 'react-router-dom';

export const useRegularTemplateData = () => {
  const { id } = useParams<{ id: string }>();
  const { data: orderData } = useGetOrderWithDetailsByIdQuery({ id: id! }, { skip: !id });
  const bidtheatreOrders = orderData?.advertisements?.filter(
    (order) => order.platform === AvailablePlatformsEnum.BIDTHEATRE
  );
  const templateType = bidtheatreOrders ? bidtheatreOrders[0]?.creative?.templateType! : '';
  const templateTypeFromLS = getValueFromLs(EDITOR_LS_CONSTANTS.TEMPLATE_TYPE);
  const isOverlayDefault = useAppSelector((state) => state.creativeStepSliceReducer.isOverlayDefault);

  const isRegularTemplate = () => {
    return (
      templateTypeFromLS === TemplateType.REGULAR_TEMPLATE ||
      (templateTypeFromLS == null && (templateType === TemplateType.REGULAR_TEMPLATE || templateType === ''))
    );
  };

  const isNotOverlayOrRegularTemplate = !isOverlayDefault || templateTypeFromLS === TemplateType.REGULAR_TEMPLATE;

  return isRegularTemplate() && isNotOverlayOrRegularTemplate;
};
