import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import SormeglerenDeltaSinglePreview from './SormeglerenDeltaSinglePreview';
import WebmeglerDeltaSinglePreview from './WebmeglerDeltaSinglePreview';
import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';

const DeltaPreviewResolver: FC<IExtendedPreviewPropsModel> = ({
  headline,
  description,
  caption,
  propertyPriceQuote,
  error,
  errorMessage,
  propertyType,
  areaInUsage,
  propertyDebt,
  ownershipType,
  companyExternalId,
  viewDate,
}): JSX.Element => {
  const switchPreview = (companyExternalId: string) => {
    switch (companyExternalId) {
      case ExternalCompanyIdEnum.EIE:
      case ExternalCompanyIdEnum.EIE_VISMA:
        return (
          <WebmeglerDeltaSinglePreview
            headline={headline}
            description={description}
            propertyPriceQuote={propertyPriceQuote}
            caption={caption}
            propertyType={propertyType}
            areaInUsage={areaInUsage}
            propertyDebt={propertyDebt}
            ownershipType={ownershipType}
            viewDate={viewDate}
          />
        );
      case ExternalCompanyIdEnum.SORMEGLEREN:
        return (
          <SormeglerenDeltaSinglePreview
            headline={headline}
            description={description}
            propertyPriceQuote={propertyPriceQuote}
            caption={caption}
            propertyType={propertyType}
            areaInUsage={areaInUsage}
            propertyDebt={propertyDebt}
            ownershipType={ownershipType}
            viewDate={viewDate}
          />
        );
      default:
        return (
          <Box component="div" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6">No preview available</Typography>
          </Box>
        );
    }
  };

  return (
    <div>
      <Box component="div">
        <Paper sx={{ border: error ? '1px solid red' : '', p: 1, justifyContent: 'center', display: 'flex' }}>
          {error && errorMessage && (
            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="h6" color="error" sx={{ p: 2, pl: 3 }}>
                {errorMessage}
              </Typography>
            </Box>
          )}
          <div>{switchPreview(companyExternalId as string)}</div>
        </Paper>
      </Box>
    </div>
  );
};

export default DeltaPreviewResolver;
