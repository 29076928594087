import validateCreatives from '@/editor/services/creatives/creatives-validator.service';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import {
  selectActivePlatforms,
  selectCreativeFormsState,
  selectCreativeSubmissionProgress,
  selectSelectedCreativeType,
} from '@/editor/store/selectors/creative.selectors';
import { useAppSelector } from '@/store/hooks/redux';
import { useEffect } from 'react';
import { useCreativesService } from './useCreativeService';
import { setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';

export const useSubmitCreatives = (handleChangeActiveStep: (step: number) => void) => {
  const renderedFormSubmissionState = useAppSelector(selectCreativeSubmissionProgress);
  const creativeFormsState = useAppSelector(selectCreativeFormsState);
  const platformList = useAppSelector(selectActivePlatforms);
  const selectedCreativeType = useAppSelector(selectSelectedCreativeType);

  const { creativeService, isLoading } = useCreativesService();

  useEffect(() => {
    if (!renderedFormSubmissionState.active) {
      return;
    }

    savingAction();
  }, [renderedFormSubmissionState.active]);

  const savingAction = async () => {
    console.log('saving action');
    if (
      !validateCreatives(
        creativeFormsState,
        platformList.filter((el: PlatformType) => el !== AvailablePlatformsEnum.DELTA),
        selectedCreativeType
      )
    ) {
      return;
    }

    const response = await creativeService.create(platformList, selectedCreativeType, creativeFormsState);

    console.log(response);

    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATED_CREATIVES, JSON.stringify(response));

    handleChangeActiveStep(3);
  };

  return { isLoading };
};
