import { AvailablePlatformsEnum } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { IProductBudgetModel } from '@/shared/models/products/product.model';

export interface IPlatformCalculationOnBudgetProps {
  selectedBudget: IBudgetModel | IProductBudgetModel;
}

export const platformsBySelectedType = {
  [CreativeTypesEnum.SINGLE]: [
    AvailablePlatformsEnum.META,
    AvailablePlatformsEnum.SNAPCHAT,
    AvailablePlatformsEnum.DELTA,
    AvailablePlatformsEnum.BIDTHEATRE,
    AvailablePlatformsEnum.BOARDS,
  ],
  [CreativeTypesEnum.DYNAMIC]: [
    AvailablePlatformsEnum.META,
    AvailablePlatformsEnum.SNAPCHAT,
    AvailablePlatformsEnum.BIDTHEATRE,
    AvailablePlatformsEnum.DELTA,
    AvailablePlatformsEnum.BOARDS,
  ],
  [CreativeTypesEnum.CAROUSEL]: [
    AvailablePlatformsEnum.META,
    AvailablePlatformsEnum.SNAPCHAT,
    AvailablePlatformsEnum.BIDTHEATRE,
  ],
  [CreativeTypesEnum.VIDEO]: [AvailablePlatformsEnum.META, AvailablePlatformsEnum.BIDTHEATRE],
};
