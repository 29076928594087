import { BudgetVariantEnum } from '@/shared/models/budgets/budget.model';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IProductBudgetModel } from '@/shared/models/products/product.model';

const baseUrl = 'products';

const addProductVariant = (response: IProductBudgetModel[]): IProductBudgetModel[] => {
  return response.map((budget) => ({
    ...budget,
    budgetVariant: BudgetVariantEnum.PRODUCT,
  }));
};

export const customerProductApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllProductsForCustomer: build.query<IProductBudgetModel[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
      }),
      transformResponse: (response: IProductBudgetModel[]) => {
        return addProductVariant(response);
      },
      providesTags: (result) => providesList(result ?? [], 'CustomerProductsBudgets', TypeIdsDefinitions.listTypeId),
    }),
    // getAllProductsForCustomer: build.query<IProductBudgetModel[], { estateType?: string }>({
    //   query: ({ estateType }) => ({
    //     url: `${baseUrl}/list/all`,
    //     params: { estateType },
    //   }),
    // }),
  }),
});

export const { useGetAllProductsForCustomerQuery } = customerProductApi;
