import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FormikProvider, useFormik } from 'formik';
import { FC, useEffect, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import CommonTextField from '../../../../../../shared/components/CommonTextField';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import {
  setcreativeSubmissionProgress,
  setFieldOfCreativeFormForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { AvailablePlatformsEnum } from '../../../../constants/available-platforms';
import CreativeTypesEnum from '../../../../constants/creatives-types.enum';
import { IEditorOrderFormPropertiesBaseModel } from '../../editor-order-form-properties-base.model';
import BasicCreativeForm from '../BasicCreativeForm';
import { ISingleCreativeFormValuesModel } from '../models/single-creative-form-values.model';
import { basicBidtheatreCreativeSchema } from '../shared/basic-validation-schema';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import SendOutlined from '@mui/icons-material/SendOutlined';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';
import BoardsPreviewResolver from '@/shared/components/platform-previews/previews/boards/BoardsPreviewResolver';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import { TemplateType } from '@/editor/shared/constants/template-type.enum';

// TODO REFACTOR !!!

export interface IBoardsSingleCreativeFormProps
  extends IEditorOrderFormPropertiesBaseModel<ISingleCreativeFormValuesModel> {}

const BoardsSingleCreativeForm: FC<IBoardsSingleCreativeFormProps> = ({
  initialValues,
  updating,
  externalCompanyId,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const singleReduxFormState = useAppSelector(
    (state) =>
      state.creativeStepSliceReducer.creativeFormsState[AvailablePlatformsEnum.BOARDS][CreativeTypesEnum.SINGLE]
  );
  const platform = AvailablePlatformsEnum.BOARDS;
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const { t } = useTranslation();
  const { data: userData } = useGetMeQuery();

  const formik = useFormik({
    initialValues: {
      ...initialValues!,
      nativeAd: true,
      employeeName: initialValues?.employeeName ?? '',
      employeePhone: initialValues?.employeePhone ?? '',
      employeeEmail: initialValues?.employeeEmail ?? '',
      employeeAvatar: initialValues?.employeeAvatar ?? '',
      doohBrokerText: initialValues?.doohBrokerText ?? '',
      doohTemplateType: initialValues?.doohTemplateType ?? TemplateType.AFTERMAEKETING_TEMPLATE,
      description: initialValues?.description ?? '',
    },
    validationSchema: basicBidtheatreCreativeSchema.concat(
      Yup.object({
        media: Yup.object().nullable().required('Media required'),
      })
    ),
    onSubmit: () => {},
  });

  useEffect(() => {
    formik.setFieldValue('media', singleReduxFormState?.media);
  }, [singleReduxFormState?.media]);

  const { handleSubmit } = useFormSubmission({
    formik,
    formSubmissionProgress,
    updating,
    platform: AvailablePlatformsEnum.BOARDS,
  });

  const handleUpdate = () => {
    const { caption, viewDate, link, description, propertyEstateType, propertyDescription2 } = formik.values;
    formik.setTouched({
      caption: true,
      link: true,
      description: true,
    });

    basicBidtheatreCreativeSchema
      .validate({
        caption,
        viewDate,
        propertyEstateType,
        propertyDescription2,
        link,
        description,
      })
      .then(() => {
        dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
      })
      .catch((validationError) => {
        dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: platform }));
        toast.error(validationError.errors);
      });
  };

  const getTemplateValue = () => {
    var templateValue =
      initialValues?.doohTemplateType !== undefined ? initialValues?.doohTemplateType : TemplateType.AFTERMAEKETING_TEMPLATE;
    formik.setFieldValue('doohTemplateType', templateValue);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BOARDS,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'doohTemplateType',
        value: templateValue,
      })
    );
  };
  const getViewDateValue = () => {
    if (formik.getFieldMeta('propertyDescription2').value) {
      var viewDate = formik.getFieldMeta('propertyDescription2').value;
    } else {
      var viewDate = formik.getFieldMeta('viewDate').value;
    }

    formik.setFieldValue('viewDate', viewDate);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BOARDS,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'viewDate',
        value: viewDate,
      })
    );
  };

  const getDebtValue = () => {
    if (formik.getFieldMeta('bidStateDebt').value) {
      var stateDebt = formik.getFieldMeta('bidStateDebt').value;
    } else {
      var stateDebt = formik.getFieldMeta('stateDebt').value;
    }

    formik.setFieldValue('stateDebt', stateDebt);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        platform: AvailablePlatformsEnum.BOARDS,
        creativeType: CreativeTypesEnum.SINGLE,
        field: 'stateDebt',
        value: stateDebt,
      })
    );
  };

  const getPriceValue = () => {
    if (formik.getFieldMeta('bidPriceQuote').value) {
      var priceQuote = formik.getFieldMeta('bidPriceQuote').value;
    } else {
      var priceQuote = formik.getFieldMeta('priceQuote').value;
    }

    formik.setFieldValue('priceQuote', priceQuote);

    dispatch(
      setFieldOfCreativeFormForPlatform({
        creativeType: CreativeTypesEnum.SINGLE,
        platform: AvailablePlatformsEnum.BOARDS,
        field: 'priceQuote',
        value: priceQuote,
      })
    );
  };

  useEffect(() => {
    handleSubmit();
    getViewDateValue();
    getDebtValue();
    getPriceValue();
    getTemplateValue();
  }, [formSubmissionProgress.active]);

  const TamplateType = [
    { value: TemplateType.AFTERMAEKETING_TEMPLATE, title: 'Solgt design' },
    { value: TemplateType.REGULAR_TEMPLATE, title: 'Til salgs design' },
  ];

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          userData={userData}
          basePreviews={[BoardsPreviewResolver]}
          concretePreviews={[BoardsPreviewResolver]}
          areFieldsRequired={true}
          platform={AvailablePlatformsEnum.BOARDS}
          initialValues={initialValues!}
          externalCompanyId={externalCompanyId}
          additionalFields={
            <div>
              <CommonTextField
                sx={{ mb: 2 }}
                type="text"
                name="link"
                label={`${t('link')}`}
                value={formik.getFieldMeta('link').value}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  dispatch(
                    setFieldOfCreativeFormForPlatform({
                      platform: AvailablePlatformsEnum.BOARDS,
                      creativeType: CreativeTypesEnum.SINGLE,
                      field: 'link',
                      value: e.target.value,
                    })
                  );
                }}
              />
              <Grid container>
                  <FormControl sx={{ mb: 2 }} fullWidth>
                    <InputLabel id="template-type-select-label">Template Type</InputLabel>
                    <Select
                      name="Template Type"
                      disabled={updating}
                      labelId="template-type-select-label"
                      id="template-type-select"
                      label="Template Type"
                      value={formik.getFieldMeta('doohTemplateType').value}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.setFieldValue('doohTemplateType', e.target.value);
                        console.log(e.target.value);
                        dispatch(
                          setFieldOfCreativeFormForPlatform({
                            platform: AvailablePlatformsEnum.BOARDS,
                            creativeType: CreativeTypesEnum.SINGLE,
                            field: 'doohTemplateType',
                            value: e.target.value,
                          })
                        );
                      }}
                      required
                      input={<OutlinedInput label={t('Template Type')}></OutlinedInput>}
                      renderValue={(value: string) => (
                        <Box component="div">{TamplateType?.find((el) => el.value === value)?.title}</Box>
                      )}>
                      {TamplateType?.map(({ title, value }: any) => (
                        <MenuItem key={value} value={value}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>

              {formik.values.doohTemplateType === TemplateType.AFTERMAEKETING_TEMPLATE && (
                <>
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="employeeName"
                    label={`${t('Broker Name')}`}
                    value={formik.getFieldMeta('employeeName').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'employeeName',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="doohBrokerText"
                    label={`${t('Broker Text')}`}
                    value={formik.getFieldMeta('doohBrokerText').value}
                    multiline
                    minRows={2}
                    maxRows={5}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'doohBrokerText',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </>
              )}
              {formik.values.doohTemplateType === TemplateType.REGULAR_TEMPLATE && (
                <>
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="description"
                    label={`${t('description')} ${(formik.getFieldMeta('description').value as string)?.length ?? 0}/255`}
                    value={formik.getFieldMeta('description').value as string}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'areaInUsage',
                          value: e.target.value,
                        })
                      );
                    }}
                    multiline
                    minRows={2}
                    maxRows={4}
                    error={Boolean(
                      formik.getFieldMeta('description').touched && formik.getFieldMeta('description').error
                    )}
                    fullWidth
                    helperText={formik.getFieldMeta('description').touched && formik.getFieldMeta('description').error}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="ownershipType"
                    label={`${t('ownershipType')} ${
                      (formik.getFieldMeta('ownershipType').value as string)?.length ?? 0
                    }/255*`}
                    value={formik.getFieldMeta('ownershipType').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'ownershipType',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="areaInUsage"
                    label={`${t('areaInUsage')} ${(formik.getFieldMeta('areaInUsage').value as string)?.length ?? 0}/255*`}
                    value={formik.getFieldMeta('areaInUsage').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'areaInUsage',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="propertyDescription2"
                    label={`${t('viewDate')} ${(formik.getFieldMeta('viewDate').value as string)?.length ?? 0}/45*`}
                    value={formik.getFieldMeta('viewDate').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'propertyDescription2',
                          value: e.target.value,
                        })
                      );
                      formik.setFieldValue('viewDate', e.target.value);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'viewDate',
                          value: e.target.value,
                        })
                      );
                    }}
                    helperText={formik.errors.viewDate}
                    error={Boolean(formik.errors.viewDate)}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="bidStateDebt"
                    label={`${t('propertyDebt')} ${(formik.getFieldMeta('stateDebt').value as string)?.length ?? 0}/255*`}
                    value={formik.getFieldMeta('stateDebt').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'bidStateDebt',
                          value: e.target.value,
                        })
                      );
                      formik.setFieldValue('stateDebt', e.target.value);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'stateDebt',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="bidPriceQuote"
                    label={`${t('propertyPriceQuote')} ${
                      (formik.getFieldMeta('priceQuote').value as string)?.length ?? 0
                    }/255*`}
                    value={formik.getFieldMeta('priceQuote').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'bidPriceQuote',
                          value: e.target.value,
                        })
                      );
                      formik.setFieldValue('priceQuote', e.target.value);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          platform: AvailablePlatformsEnum.BOARDS,
                          creativeType: CreativeTypesEnum.SINGLE,
                          field: 'priceQuote',
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                  <CommonTextField
                    sx={{ mb: 2 }}
                    type="text"
                    name="propertyEstateType"
                    label={`${t('propertyEstateType')} ${
                      (formik.getFieldMeta('propertyEstateType').value as string)?.length ?? 0
                    }/20*`}
                    value={formik.getFieldMeta('propertyEstateType').value}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      dispatch(
                        setFieldOfCreativeFormForPlatform({
                          creativeType: CreativeTypesEnum.SINGLE,
                          platform: AvailablePlatformsEnum.BOARDS,
                          field: 'propertyEstateType',
                          value: e.target.value,
                        })
                      );
                    }}
                    helperText={formik.errors.propertyEstateType}
                    error={Boolean(formik.errors.propertyEstateType)}
                  />
                </>
              )}
            </div>
          }
        />
        <Grid container>
          {updating && (
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </div>
  );
};
export default BoardsSingleCreativeForm;
