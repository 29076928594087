import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';

export const bidtheatreClientStyles: any = {
  [ExternalCompanyIdEnum.SMG]: {
    backgroundColor: '#859419',
    logo: '/images/svenska/svenska_logo.png',
    fontFamily: 'Arial',
    textColor: '#fff',
    primaryColor: '#859419',
  },
  [ExternalCompanyIdEnum.MSPECS]: {
    backgroundColor: '#255a88',
    logo: '/images/mspecs/mspecs_logo.png',
    textColor: '#fff',
    primaryColor: '#255a88',
  },
  [ExternalCompanyIdEnum.STRAND_MAKLERI]: {
    backgroundColor: '#161b1e',
    logo: '/images/strandmakleri/strand_logo.png',
    fontFamilyForBrokerName: 'Roboto',
    fontFamily: 'Montserrat',
    textColor: '#fff',
    primaryColor: '#161b1e',
  },
  [ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI]: {
    backgroundColor: '#5a7955',
    logo: '/images/studiofastighetsmakleri/studio_logo.png',
    fontFamilyForBrokerName: 'Montserrat',
    fontFamily: 'Montserrat',
    textColor: '#fff',
    primaryColor: '#5a7955',
  },
  [ExternalCompanyIdEnum.HOME_BY_DEAN]: {
    backgroundColor: '#aa0061',
    logo: '/images/homebydean/homebydean_logo.png',
    fontFamilyForBrokerName: 'ITCGaramondStd-LtCond',
    fontFamily: 'ITCGaramondStd-LtCond',
    textColor: '#fff',
    primaryColor: '#aa0061',
  },
  [ExternalCompanyIdEnum.MAKLARTEAMET]: {
    backgroundColor: '#74243b',
    logo: '/images/maklarteamet/maklarteamet.png',
    fontFamilyForBrokerName: 'Montserrat',
    fontFamily: 'Montserrat',
    textColor: '#fff',
    primaryColor: '#74243b',
  },
  [ExternalCompanyIdEnum.WALLIN_CO_FASTIGHETSMAKLARE_AB]: {
    backgroundColor: '#276e96',
    logo: '/images/Wallin/Wallin.jpg',
    fontFamilyForBrokerName: 'Playfair Display',
    fontFamily: 'Playfair Display',
    textColor: '#fff',
    primaryColor: '#276e96',
  },
  [ExternalCompanyIdEnum.H_MAKLARE_AB]: {
    backgroundColor: '#ac8811',
    logo: '/images/hmaklare/hmaklare.png',
    fontFamilyForBrokerName: 'Roboto',
    fontFamily: 'Roboto',
    textColor: '#fff',
    primaryColor: '#ac8811',
  },
  [ExternalCompanyIdEnum.CHRISTANDER]: {
    backgroundColor: '#d0b67b',
    logo: '/images/christander/christander.jpg',
    fontFamilyForBrokerName: 'OpenSans-Regular, sans-serif, Arial',
    fontFamily: 'OpenSans-Regular, sans-serif, Arial',
    textColor: '#fff',
    primaryColor: '#d0b67b',
  },
  [ExternalCompanyIdEnum.EIENDOM_BROERBY]: {
    backgroundColor: '#010101',
    logo: '/images/eiendom/eiendom.jpg',
    fontFamilyForBrokerName: 'Poppins, Sans-serif',
    fontFamily: 'Poppins, Sans-serif',
    textColor: '#fff',
    primaryColor: '#010101',
  },
};
