import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import Loader from '@/shared/components/Loader';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import Grid from '@mui/material/Grid';
import { FC, type JSX } from 'react';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';
import usePrepareCreatives from './hooks/usePrepareCreatives';
import CreativesTypeResolver from '@/editor/shared/components/forms/creatives/CreativesTypesResolver';
import { CreativeSubmissionControls } from './components/CreativeSubmissionControls';

const CreativesStep: FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const isCreativeFormInitialized = useAppSelector((state) => state.creativeStepSliceReducer.isInitialized);

  const { loading } = usePrepareCreatives();

  const platformsFromStoreIds = useAppSelector((state) => state.editorActivePlatformsReducer.activePlatforms);

  const activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  const platformList: PlatformType[] = platformsFromStoreIds.length === 0 ? activePlatforms : platformsFromStoreIds;

  return (
    <>
      {loading || !isCreativeFormInitialized ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item xs={12} sx={{ p: 1 }}>
            <CreativesTypeResolver availablePlatforms={platformList} creativeType={selectedCreativeType} />
          </Grid>

          <Grid item xs={12} sx={{ p: 1 }}>
            <CreativeSubmissionControls
              handleBackBtnClicked={handleBackBtnClicked}
              handleChangeActiveStep={handleChangeActiveStep}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CreativesStep;
