import { TypeIdsDefinitions, api } from '@/shared/services/api/api';
import providesList from '@/shared/services/helpers/cache.helper';
import { BudgetVariantEnum, IBudgetModel } from '@/shared/models/budgets/budget.model';

const baseUrl = 'budgets';

const addBudgetVariant = (response: IBudgetModel[]): IBudgetModel[] => {
  return response.map((budget) => ({
    ...budget,
    budgetVariant: BudgetVariantEnum.STANDARD,
  }));
};

export const customerBudgetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBudgets: build.query<IBudgetModel[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
      }),
      transformResponse: (response: IBudgetModel[]) => {
        return addBudgetVariant(response);
      },
      providesTags: (result) => providesList(result ?? [], 'CustomerBudgets', TypeIdsDefinitions.listTypeId),
    }),
    getAllBudgetsForCustomer: build.query<IBudgetModel[], { estateType?: string }>({
      query: ({ estateType }) => ({
        url: `${baseUrl}/list/all`,
        params: { estateType },
      }),
      transformResponse: (response: IBudgetModel[]) => {
        return addBudgetVariant(response);
      },
    }),
  }),
});

export const { useGetAllBudgetsQuery, useGetAllBudgetsForCustomerQuery } = customerBudgetsApi;
