import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { ISliderValuesPerPlatform, setSlidersInitialState } from '@/editor/store/reducers/selected-platforms.slice';
import { useEffect, useState } from 'react';
import { getValueFromLs } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { IPlatformCalculationOnBudgetProps } from '../types/platformTypes';
import { findSliderValue, getPlatforms } from '@/editor/shared/services/platform-calculations-service';

export const usePlatformCalculationOnBudget = (props: IPlatformCalculationOnBudgetProps) => {
  const { selectedBudget } = props;
  const dispatch = useAppDispatch();

  const [possibleSliders, setPossibleSliders] = useState<ISliderValuesPerPlatform[]>();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const slidersState = useAppSelector((state) => state.editorActivePlatformsReducer.slidersState);

  const activePlatformsFromLS =
    getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) !== null
      ? JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string)
      : [];

  useEffect(() => {
    const platforms = getPlatforms(selectedBudget, selectedCreativeType);
    if (!platforms) return;

    const sliderValuesPerPlatform = platforms.map((platform) => ({
      platform: platform,
      value: findSliderValue(platform, slidersState, selectedBudget, activePlatformsFromLS),

      // if activePlatforms is = 0 that means that this is the initial run of the function
      isActive: platforms.length > activePlatformsFromLS.length ? true : activePlatformsFromLS.includes(platform),
    }));

    setPossibleSliders(sliderValuesPerPlatform);
    dispatch(setSlidersInitialState(sliderValuesPerPlatform));

    return () => setPossibleSliders([]);
  }, [selectedBudget, dispatch, selectedCreativeType]);

  return { possibleSliders };
};
