import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import {
  ICreativeFormsStatePerPlatform,
  setCreativeFormValueForPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { useAppSelector } from '@/store/hooks/redux';
import { store } from '@/store/store';

interface IAftermarketingFormValues extends IBasicCreativeFormValues {
  message: string;
}

export const creativeFormService = {
  setFormValues(
    formValues: Partial<IAftermarketingFormValues>,
    platform: PlatformType,
    creativeType: keyof ICreativeFormsStatePerPlatform
  ) {
    const state = store.getState();
    const platformFormValues = state.creativeStepSliceReducer.creativeFormsState[platform][creativeType];

    if (formValues) {
      // since 'headline field in aftermarketing is called 'message'
      // the object has to be remapped
      const { message, ...rest } = formValues;
      const newFormValues = { ...rest, headline: message, priceQuote: '', bidPriceQuote: '', stateDebt: '', bidStateDebt: '', propertyDescription2: '', viewDate: '' };

       const { caption, description, headline, link, viewDate, propertyDescription2, stateDebt, bidStateDebt, priceQuote, bidPriceQuote, ...other } = platformFormValues as IBasicCreativeFormValues;

      const mergedFormValues = {
        ...newFormValues,
        ...other,
      };

      store.dispatch(setCreativeFormValueForPlatform({ creativeType, platform, values: mergedFormValues }));
    }
  },
};
