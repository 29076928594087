import { ICarouselCreativeItemFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';
import {
  AvailablePlatformsEnum,
  platformsToCropImage,
  PlatformType,
} from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { TemplateType } from '@/editor/shared/constants/template-type.enum';
import {
  addedMediaOnDynamic,
  ICreativeFormsStatePerPlatform,
  ICreativeStepState,
  removeMediaOnDynamic,
  removeMediaOnSingle,
  setMediaOnCarouselItem,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import { IMediaLibraryDrawerState, setImageCropper } from '@/editor/store/reducers/modals-drawers.slice';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import toast from 'react-hot-toast';

// TODO REFACTOR

const useHandleImageClick = (
  isImageSelectedByPlatform: (platform: PlatformType) => boolean,
  drawerState: IMediaLibraryDrawerState,
  creativeState: ICreativeStepState
) => {
  const dispatch = useAppDispatch();

  const handleImageClick = (platformOption: PlatformType, image: IMediaLibraryImageModel): void => {
    if (isImageSelectedByPlatform(platformOption)) {
      removeImage(platformOption, image);
      return;
    }

    if (platformOption == AvailablePlatformsEnum.BIDTHEATRE) {
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.TEMPLATE_TYPE, TemplateType.REGULAR_TEMPLATE);
    }
    if (ruleForDynamicAndCarousel(platformOption)) {
      toast.error('You can select only 5 images for dynamic and carousel creatives');
      return;
    }
    addingImage(platformOption, image);
  };

  const addingImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const platform = platformOption as PlatformType;

    if (!checkDimensionsOfImage(platform, image)) {
      return;
    }

    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    switch (creativeType) {
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, image);
        break;
      case CreativeTypesEnum.DYNAMIC:
        dispatch(addedMediaOnDynamic({ media: image, platform }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(setMediaOnSingle({ media: image, platform }));
        break;
    }
  };

  const removeImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    const platform = platformOption as PlatformType;
    switch (creativeType) {
      case CreativeTypesEnum.DYNAMIC:
        dispatch(removeMediaOnDynamic({ platform, media: image }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(removeMediaOnSingle({ platform }));
        break;
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, null);
        break;
    }
  };

  const checkDimensionsOfImage = (platform: PlatformType, image: IMediaLibraryImageModel): boolean => {
    const platformConfigToCrop = platformsToCropImage.find((el) => el.platform === platform);

    if (
      platformConfigToCrop &&
      (image.width !== platformConfigToCrop.width || image.height !== platformConfigToCrop.height)
    ) {
      dispatch(
        setImageCropper({
          isOpened: true,
          image: image,
          platform: platform,
          propertyInternalId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID),
          widthToCrop: platformConfigToCrop.width,
          heightToCrop: platformConfigToCrop.height,
        })
      );

      return false;
    }

    return true;
  };

  const updateCarouselItemImageIfNeeded = (
    platformOption: PlatformType,
    media: IMediaLibraryImageModel | null
  ): void => {
    if (drawerState.carouselItemIndex !== undefined) {
      dispatch(
        setMediaOnCarouselItem({
          media,
          index: drawerState.carouselItemIndex,
          platform: platformOption as PlatformType,
        })
      );
    }
    if (media === null && drawerState.carouselItemIndex !== undefined) {
      toast.error('This images is already selected!');
    }
  };

  const ruleForDynamicAndCarousel = (platform: PlatformType): boolean => {
    const form = creativeState.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
    const medias: IMediaLibraryImageModel[] | ICarouselCreativeItemFormValuesModel[] =
      drawerState.creativeType === CreativeTypesEnum.DYNAMIC
        ? form.dynamic?.creativeMedias
        : form.carousel?.creativeItems;
    return medias?.length >= 5;
  };

  return { handleImageClick };
};

export default useHandleImageClick;
