import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';

export const isCompanyQualifiedForBrokerService = (externalId: string): boolean => {
  const qualifiedCompanies = new Set([
    ExternalCompanyIdEnum.SMG,
    ExternalCompanyIdEnum.FREMEIENDOMSMEGLING,
    ExternalCompanyIdEnum.MSPECS,
    ExternalCompanyIdEnum.STRAND_MAKLERI,
    ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI,
    ExternalCompanyIdEnum.HOME_BY_DEAN,
    ExternalCompanyIdEnum.MAKLARTEAMET,
    ExternalCompanyIdEnum.WALLIN_CO_FASTIGHETSMAKLARE_AB,
    ExternalCompanyIdEnum.H_MAKLARE_AB,
    ExternalCompanyIdEnum.CHRISTANDER,
    ExternalCompanyIdEnum.EIENDOM_BROERBY,
  ]);

  return qualifiedCompanies.has(externalId as ExternalCompanyIdEnum);
};
