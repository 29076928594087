import CommonButton from '@/shared/components/CommonButton';
import { useAppDispatch } from '@/store/hooks/redux';
import ArrowBackTwoTone from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import { FC, useEffect, type JSX } from 'react';
import { resetValidPlatforms, setcreativeSubmissionProgress } from '@/editor/store/reducers/creative-step.slice';
import { useTranslation } from 'react-i18next';
import { IBaseStepperComponentPropertiesModel } from '../../component-properties.model';
import { useSubmitCreatives } from '../hooks/useSubmitCreatives';

export const CreativeSubmissionControls: FC<IBaseStepperComponentPropertiesModel> = ({
  handleBackBtnClicked,
  handleChangeActiveStep,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { isLoading } = useSubmitCreatives(handleChangeActiveStep);

  const handleNextStepClick = async () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: false, platform: null }));
  };

  const handleBackStepClicked = () => {
    dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: null }));
    dispatch(resetValidPlatforms());

    if (handleBackBtnClicked) {
      handleBackBtnClicked();
    }
  };

  return (
    <Card sx={{ backgroundColor: '#FFF', display: 'flex', justifyContent: 'space-between', p: 1.5 }}>
      <CommonButton
        size="small"
        onClick={handleBackStepClicked}
        disabled={isLoading}
        variant={'outlined'}
        startIcon={<ArrowBackTwoTone fontSize="small" />}>
        {t('back')}
      </CommonButton>
      <CommonButton
        size="small"
        onClick={handleNextStepClick}
        disabled={isLoading}
        endIcon={<ArrowForward fontSize="small" />}>
        {t('submit')}
      </CommonButton>
    </Card>
  );
};
