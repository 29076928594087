import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';

export const nativeClientStyles: any = {
  [ExternalCompanyIdEnum.SMG]: {
    logo: '/images/svenska/svenska_logo.png',
    fontFamily: 'GothamNarrowBook',
    primaryColor: '#859419',
  },
  [ExternalCompanyIdEnum.MSPECS]: {
    logo: '/images/mspecs/mspecs_logo.png',
    primaryColor: '#255a88',
  },
  [ExternalCompanyIdEnum.SORMEGLEREN]: {
    logo: '/images/sormegleren/sormegleren_logo_a_rgb.png',
    primaryColor: '#231464',
  },
  [ExternalCompanyIdEnum.FREMEIENDOMSMEGLING]: {
    logo: '/images/fremeiendomsmegling/Native_Frem_Eiendomsmegling.png',
    primaryColor: 'rgb(244, 125, 75)',
  },
  [ExternalCompanyIdEnum.STRAND_MAKLERI]: {
    logo: '/images/strandmakleri/strand_logo.png',
    fontFamilyOne: 'Roboto',
    fontFamilyTwo: 'Montserrat',
    primaryColor: '#000',
  },
  [ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI]: {
    logo: '/images/studiofastighetsmakleri/studio_logo.png',
    fontFamilyOne: 'Montserrat',
    fontFamilyTwo: 'Montserrat',
    primaryColor: '#5a7955',
  },
  [ExternalCompanyIdEnum.HOME_BY_DEAN]: {
    logo: '/images/homebydean/homebydean_logo.png',
    fontFamilyOne: 'ITCGaramondStd-LtCond',
    fontFamilyTwo: 'ITCGaramondStd-LtCond',
    primaryColor: '#aa0061',
  },
  [ExternalCompanyIdEnum.MAKLARTEAMET]: {
    logo: '/images/maklarteamet/maklarteamet.png',
    fontFamilyOne: 'Montserrat',
    fontFamilyTwo: 'Montserrat',
    primaryColor: '#74243b',
  },
  [ExternalCompanyIdEnum.WALLIN_CO_FASTIGHETSMAKLARE_AB]: {
    logo: '/images/Wallin/Wallin.jpg',
    fontFamilyOne: 'Playfair Display',
    fontFamilyTwo: 'Playfair Display',
    primaryColor: '#276e96',
  },
  [ExternalCompanyIdEnum.H_MAKLARE_AB]: {
    logo: '/images/hmaklare/hmaklare.png',
    fontFamilyOne: 'Roboto',
    fontFamilyTwo: 'Roboto',
    primaryColor: '#ac8811',
  },
  [ExternalCompanyIdEnum.CHRISTANDER]: {
    logo: '/images/christander/christander.jpg',
    fontFamilyOne: 'OpenSans-Regular, sans-serif, Arial',
    fontFamilyTwo: 'OpenSans-Regular, sans-serif, Arial',
    primaryColor: '#d0b67b',
  },
  [ExternalCompanyIdEnum.EIENDOM_BROERBY]: {
    logo: '/images/eiendom/eiendom.jpg',
    fontFamilyOne: 'Poppins, Sans-serif',
    fontFamilyTwo: 'Poppins, Sans-serif',
    primaryColor: '#010101',
  },
};
