import { ICarouselCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';
import { IDynamicCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/dynamic-creative-form-values.model';
import { ISingleCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/single-creative-form-values.model';
import { IVideoCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/video-creative-form.values.model';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum, { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { ICreativeFormsState } from '@/editor/store/reducers/creative-step.slice';
import { ExternalCompanyIdEnum } from '@/shared/constants/external-company-id.enum';
import { IBaseCreativeModel } from '@/shared/models/creatives/base-creative.model';
import toast from 'react-hot-toast';

export interface MutationHandler<TRequest, TResponse> {
  (data: TRequest): {
    unwrap: () => Promise<TResponse>;
  };
}

export class CreateCreativesService {
  constructor(
    private t: (key: string) => string,
    private companyExternalId: ExternalCompanyIdEnum,
    private mutations?: {
      createSingleCreativesBatch?: MutationHandler<any, any>;
      createDynamicCreativesBatch?: MutationHandler<any, any>;
      createCarouselCreativesBatch?: MutationHandler<any, any>;
      createVideoCreativeBatch?: MutationHandler<any, any>;
      brokerMutation?: MutationHandler<any, any>;
    }
  ) {}

  public create = async (
    platformList: PlatformType[],
    selectedCreativeType: CreativeType,
    creativeFormsState: ICreativeFormsState
  ): Promise<{ id: string; platform: string }[]> => {
    const standardFlowPlatforms = platformList.filter(
      (el: PlatformType) => el !== AvailablePlatformsEnum.BIDTHEATRE && el !== AvailablePlatformsEnum.BOARDS
    );

    const newsPaperPlatforms = platformList.filter(
      (el: PlatformType) => el === AvailablePlatformsEnum.BIDTHEATRE || el === AvailablePlatformsEnum.BOARDS
    );

    const [standardResults, newspaperResults] = await Promise.all([
      this.createStandard(standardFlowPlatforms, selectedCreativeType, creativeFormsState),
      this.createNewspapers(newsPaperPlatforms, CreativeTypesEnum.SINGLE, creativeFormsState),
    ]);

    console.log(standardResults, newspaperResults);

    return [...standardResults, ...newspaperResults];
  };

  private createNewspapers = async (
    platformList: PlatformType[],
    selectedCreativeType: CreativeType,
    creativeFormsState: ICreativeFormsState
  ): Promise<{ id: string; platform: string }[]> => {
    if (!platformList.length || !this.mutations?.createSingleCreativesBatch || !this.mutations?.brokerMutation) {
      return [];
    }

    const map = platformList.map((platform: PlatformType) => ({
      platform: platform,
      creative: creativeFormsState[platform][selectedCreativeType]!,
    }));

    const response = await this.handleToast(this.mutations.createSingleCreativesBatch, map);

    const bidtheatreItem = response.items.find(
      (item: { id: string; platform: string }) => item.platform === 'bidtheatre'
    );

    if (bidtheatreItem && this.isCompanyQualifiedForBroker(this.companyExternalId)) {
      console.log('here');
      const bidtheatreId = bidtheatreItem.id;
      localStorage.setItem('bidid', bidtheatreId);
      this.handleToast(this.mutations.brokerMutation, {
        id: bidtheatreId,
        creatives: map.filter((item) => item.platform === 'bidtheatre'),
      });
    }

    const boardsItem = response.items.find((item: { id: string; platform: string }) => item.platform === 'boards');

    if (boardsItem) {
      const boardsId = boardsItem.id;
      localStorage.setItem('boardsid', boardsId);

      this.handleToast(this.mutations.brokerMutation, {
        id: boardsId,
        creatives: map.filter((item) => item.platform === 'boards'),
      });
    }

    return response.items.map((item: { id: string; platform: string }) => ({ id: item.id, platform: item.platform }));
  };

  private createStandard = async (
    platformList: PlatformType[],
    selectedCreativeType: CreativeType,
    creativeFormsState: ICreativeFormsState
  ): Promise<{ id: string; platform: string }[]> => {
    if (!platformList.length) return [];

    let result: IBaseCreativeModel[] = [];

    switch (selectedCreativeType) {
      case CreativeTypesEnum.SINGLE:
        if (!this.mutations?.createSingleCreativesBatch) return [];
        const singleResponse = await this.createCreativesWithSingleElement(
          selectedCreativeType,
          this.mutations.createSingleCreativesBatch,
          platformList,
          creativeFormsState
        );
        result = singleResponse.items;
        break;
      case CreativeTypesEnum.DYNAMIC:
        if (!this.mutations?.createDynamicCreativesBatch) return [];
        const dynamicResponse = await this.createCreativesWithMultipleElements(
          selectedCreativeType,
          platformList,
          creativeFormsState,
          this.mutations.createDynamicCreativesBatch
        );
        result = dynamicResponse.items;
        break;
      case CreativeTypesEnum.CAROUSEL:
        if (!this.mutations?.createCarouselCreativesBatch) return [];
        const carouselResponse = await this.createCreativesWithMultipleElements(
          selectedCreativeType,
          platformList,
          creativeFormsState,
          this.mutations.createCarouselCreativesBatch
        );
        result = carouselResponse.items;
        break;
      case CreativeTypesEnum.VIDEO:
        if (!this.mutations?.createVideoCreativeBatch) return [];
        const videoResponse = await this.createCreativesWithSingleElement(
          selectedCreativeType,
          this.mutations.createVideoCreativeBatch,
          platformList,
          creativeFormsState
        );
        result = videoResponse.items;
        break;
    }

    return result.map((item) => ({ id: item.id, platform: item.platform }));
  };

  private createCreativesWithMultipleElements = async <
    TCreative extends ICarouselCreativeFormValuesModel | IDynamicCreativeFormValuesModel,
    TResponse = Record<'items', IBaseCreativeModel[]>,
  >(
    creativeType: CreativeType,
    platformList: PlatformType[],
    creativeFormsState: ICreativeFormsState,
    handler: MutationHandler<Array<{ platform: PlatformType; creative: TCreative }>, TResponse>
  ): Promise<TResponse> => {
    const creativeData = platformList.map((platform: PlatformType) => ({
      platform: platform,
      creative: creativeFormsState[platform][
        creativeType as keyof (typeof creativeFormsState)[typeof platform]
      ] as TCreative,
    }));

    return await this.handleToast(handler, creativeData);
  };

  private createCreativesWithSingleElement = async <
    TCreative extends ISingleCreativeFormValuesModel | IVideoCreativeFormValuesModel,
    TResponse = Record<'items', IBaseCreativeModel[]>,
  >(
    creativeType: CreativeType,
    handler: MutationHandler<Array<{ platform: PlatformType; creative: TCreative }>, TResponse>,
    platformList: PlatformType[],
    creativeFormsState: ICreativeFormsState
  ): Promise<TResponse> => {
    const map = platformList.map((platform: PlatformType) => ({
      platform: platform,
      creative: creativeFormsState[platform][creativeType]! as TCreative,
    }));

    return await this.handleToast(handler, map);
  };

  private handleToast = async (handler: MutationHandler<any, any>, data: any) => {
    return await toast.promise(handler(data).unwrap(), {
      loading: this.t('creatingCreatives'),
      success: this.t('successfullyCreated'),
      error: this.t('somethingWrong'),
    });
  };

  private isCompanyQualifiedForBroker(externalId: string): boolean {
    const qualifiedCompanies = [
      ExternalCompanyIdEnum.SMG,
      ExternalCompanyIdEnum.FREMEIENDOMSMEGLING,
      ExternalCompanyIdEnum.MSPECS,
      ExternalCompanyIdEnum.STRAND_MAKLERI,
      ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI,
      ExternalCompanyIdEnum.HOME_BY_DEAN,
      ExternalCompanyIdEnum.MAKLARTEAMET,
      ExternalCompanyIdEnum.WALLIN_CO_FASTIGHETSMAKLARE_AB,
      ExternalCompanyIdEnum.H_MAKLARE_AB,
      ExternalCompanyIdEnum.CHRISTANDER,
      ExternalCompanyIdEnum.EIENDOM_BROERBY,
    ];

    return Boolean(externalId && qualifiedCompanies.includes(externalId as ExternalCompanyIdEnum));
  }
}
